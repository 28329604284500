import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';
import { withPrismicUnpublishedPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, PreviewBody } from 'components';
import FourOhFourImage from 'images/404.svg';
import { container, image, content } from './404.module.scss';

const FourOhFour = ({ location }) => {
  const [isPreviewEnv, setIsPreviewEnv] = useState(false);

  useEffect(() => {
    const { PrismicToolbar } = window;
    setIsPreviewEnv(!!PrismicToolbar);
  }, []);

  if (isPreviewEnv) {
    return (
      <Layout location={location}>
        <PreviewBody />
      </Layout>
    );
  }

  return (
    <Layout location={location}>
      <div className={`container ${container}`}>
        <img className={image} src={FourOhFourImage} alt="Opps this page has gone missing" />
        <div className={content}>
          <h2>404 Not found</h2>
          <Link className="button" to="/">
            Go back home
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default withPrismicUnpublishedPreview(FourOhFour);
